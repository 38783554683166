<!--
 * @Author: huangl
 * @Date: 2023-04-10 11:55:49
 * @LastEditors: Chenminggui111 874512298@qq.com
 * @LastEditTime: 2023-08-09 18:39:02
 * @Description: file content
 * @FilePath: \kingpuluse_pro\src\views\HomeView.vue
-->
<template>
	<div class="container">
		<div class="banner">
			<el-image
				:src="bannerImg"
				style="width: 100%; display: block"
			/>

			<div v-if="!store.state.MOBILE && !store.state.ISLOGIN">
				<div class="login-wrap">
					<h4>全系列产品管理系统</h4>
					<div class="formClass">
						<div class="phone">
							<div style="width: 80px">手机号</div>
							<el-input
								v-model="phone"
								placeholder="请输入手机号"
							/>
						</div>
						<div class="phone">
							<div style="width: 80px">验证码</div>
							<el-input
								v-model="vaild"
								placeholder="请输入验证码"
							>
								<template #append>
									<span v-if="vaildFlag">{{ num }}s</span>
									<el-button
										v-else
										@click="handleVaildBtn"
									>
										发送验证码
									</el-button>
								</template>
							</el-input>
						</div>
						<p>温馨提示：尊敬的客户，注册登录，可以随时获取最新产品咨询。</p>
						<div style="margin-top: 20px">
							<el-button
								type="primary"
								:disabled="phone.length <= 0 || vaild.length <= 0"
								class="login_btn"
								round
								color="#000000"
								@click="handleClickLogin"
							>
								登录
							</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="store.state.MOBILE && !store.state.ISLOGIN"
			class="login-mobile"
		>
			<div class="form-item">
				<p>手机号</p>
				<el-input
					v-model="phone"
					size="small"
					placeholder="请输入手机号"
				/>
			</div>
			<div class="form-item">
				<p>验证码</p>
				<el-input
					size="small"
					v-model="vaild"
					placeholder="请输入验证码"
				>
					<template #append>
						<span v-if="vaildFlag">{{ num }}s</span>
						<el-button
							v-else
							:disabled="vaildFlag"
							@click="handleVaildBtn"
						>
							发送验证码
						</el-button>
					</template>
				</el-input>
			</div>
			<div style="margin-top: 40px">
				<el-button
					color="#000000"
					:disabled="phone.length <= 0 || vaild.length <= 0"
					class="login_btn"
					round
					@click="handleClickLogin"
				>
					登录
				</el-button>
			</div>
		</div>
		<proList
			v-if="store.state.ISLOGIN && store.state.USERINFO.authFlag === 1"
			:products="products.data"
		></proList>

		<div v-if="store.state.MOBILE && showForm">
			<van-popup
				round
				:show="true"
				:close-on-click-overlay="false"
				:style="{ padding: '8px' }"
			>
				<Form />
			</van-popup>
		</div>
		<div
			class="from-wrap"
			v-if="!store.state.MOBILE && showForm"
		>
			<Form />
		</div>
	</div>
</template>

<script>
	import proList from '@/components/proList/index.vue'
	import Form from '@/components/form/index.vue'
	import { fetchGetProHome, getBannerImg } from '@/api/home'
	import { onMounted, reactive, ref, computed } from 'vue'
	import { useStore } from 'vuex'
	import { fetchGetPhoneSms, fetchPhoneLogin } from '@/api/home'

	import { showToast } from 'vant'
	export default {
		name: 'HomeView',
		components: {
			proList,
			Form
		},
		setup() {
			const store = useStore()
			const bannerImg = ref(null)

			let products = reactive({ data: [] })
			onMounted(async () => {
				getBannerImg().then(res => {
					bannerImg.value = res
				})

				const results = await fetchGetProHome()
				products.data.push(...results)
			})
			const vaildFlag = ref(false)
			let showForm = computed(() => {
				let { id, address, authFlag } = store.state.USERINFO
				return authFlag === 2 || (id && !address)
			})
			const phone = ref('')
			const vaild = ref('')
			const num = ref(60)

			const handleClickLogin = async () => {
				const params = {
					mobile: phone.value,
					code: vaild.value
				}
				fetchPhoneLogin(params)
					.then(res => {
						showToast('登录成功')
						store.commit('changeUserInfo', res)
						store.commit('chageLoginState', true)
					})
					.catch(err => {
						console.log(err)
					})
			}
			const handleVaildBtn = async () => {
				if (/^1[3456789]\d{9}$/.test(phone.value)) {
					vaildFlag.value = true
					fetchGetPhoneSms(phone.value)
						.then(() => {
							interverNum()
							showToast('验证码发送成功')
						})
						.catch(() => {
							vaildFlag.value = false
						})
				} else {
					showToast('请填写正确的手机号')
				}
			}

			// 倒计时组件
			const interverNum = () => {
				const numInt = setInterval(() => {
					if (num.value > 0) {
						num.value--
					} else {
						vaildFlag.value = false
						num.value = 60
						clearInterval(numInt)
					}
				}, 1000)
			}

			return {
				num,
				store,
				phone,
				vaild,
				showForm,
				products,
				bannerImg,
				vaildFlag,
				handleClickLogin,
				handleVaildBtn
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		margin: 0;
		width: 100%;
		position: relative;
	}
	.from-wrap {
		position: absolute;
		top: 133px;
		right: 369px;
		width: 460px;
		height: 527px;
		background-color: #fff;
		border-radius: 10px;
		padding: 18px 30px;
		box-sizing: border-box;
		overflow: hidden;
	}

	::v-deep .login-mobile {
		padding: 54px 34px;
		box-sizing: border-box;
		.form-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			p {
				width: 23%;
				font-size: 14px;
				color: #000;
				font-weight: 600;
			}
			.el-input {
				width: 77%;
				height: 30px;
				.el-input__wrapper {
					background-color: rgba(229, 229, 229, 1);
					box-shadow: none;
				}
			}
			.el-input-group__append {
				color: #fff;
				background: #000;
			}
		}
	}
	.banner {
		position: relative;
		.login-wrap {
			position: absolute;
			top: 25%;
			right: 10%;
			z-index: 992;
			h4 {
				margin: 0;
				text-align: center;
				color: #fff;
				font-size: 28px;
				margin-bottom: 18px;
				font-weight: bold;
				font-style: italic;
			}
		}
		.formClass {
			width: 300px;
			border-radius: 15px;
			padding: 24px;
			color: rgba(255, 255, 255, 1);
			font-weight: bold;
			border-radius: 10px;
			background: rgba(255, 255, 255, 0.3);
			backdrop-filter: blur(10px);
			.phone {
				margin: 20px 0;
				display: flex;
				font-size: 12px;
				align-items: center;
			}
		}
	}
	.img {
		display: flex;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.light {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.light-icon {
			width: 80px;
			height: 80px;
			margin: 0 auto;
		}
		.info {
			margin-top: 61px;
			font-size: 16px;
			color: #fff;
			font-weight: bold;
			text-align: center;
			@media screen and (min-width: 750px) {
				font-size: 26px;
			}
		}
	}
	.login_btn {
		display: flex;
		width: 50%;
		justify-content: center;
		margin: 0 auto;
	}
</style>
